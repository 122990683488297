.simple-navs_href {
  &:hover {
    color: #EA1624;
    text-decoration: none;
  }

  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
}


h1 {
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: #333333;
  margin-bottom: 20px;
}

h2 {
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #333333;
  margin-bottom: 12px;
  margin-top: 20px;
  //@include _mobile {
  //font-size: 18px;
  //line-height: 24px;
  //}
}

h3 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  //@include _mobile {
  //font-size: 16px;
  //line-height: 20px;
  //}
}

input, textarea {
  font-family: Inter;
}

.red-button {
  cursor: pointer;
  padding: 11px 20px 12px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #FFFFFF;
  background-color: #EA1624;
  border-radius: 5px;
  border: 1px solid #EA1624;
  display: inline-block;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  & span{
    color: white;

  }
  &:hover{
      color: $main-color;
      & a{
        color: $main-color !important;
      }
      & span{
        color: $main-color !important;
      }
    }

  &__offer {
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
     & span{
    color: white;
    &:hover{
      color: $main-color;
      & a{
        color: $main-color !important;
      }
      & span{
        color: $main-color !important;
      }
    }
  }

    & a {
      color: white;
    }

    &:hover {
      & .find-out-phone {
        color: $main-color;
      }

      & a {
        color: $main-color;
      }
    }
  }

  &-inverse {
    color: $main-color;
    background: white;

    &:hover {
      color: #333333 !important;
      border-color: #fc3c49;
    }
  }

  &:hover {
    background-color: white;
    color: #EA1624;
    text-decoration: none;
  }
}

.realtor-phone {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  & .short {
    font-weight: 600;
  }

  & .show {
    font-weight: 400;
    text-decoration-color: rgba(255, 255, 255, 0.4) !important;
  }

  &-wrapper {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    display: flex;
    align-items: center;

    & a {
      color: #333333;
      padding-right: 5px;
    }
  }

  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  display: flex;
  align-items: center;

  & a {
    color: $main-color;
    text-decoration: underline;
    text-underline-color: rgba(234, 22, 36, 0.3);
    font-size: 15px;
    margin-left: 10px;

    &:hover {
      text-decoration: none;
    }
  }
}

input:focus, textarea:focus {
  outline: none;
}


.collapsed__anchor {
  color: $main-color;
  border-bottom: 1px dashed rgba(234, 22, 36, 1);
  cursor: pointer;
}

.simplebar-track {
  background: #e8e7e7;
}

button {
  font-family: Inter;
}

input {
  outline: none;
}

@media (max-width: 576px) {
  h1 {
    font-size: 22px;
    line-height: 28px;
  }
  h2 {
    font-size: 18px;
    line-height: 20px;
  }
  h3 {
    font-size: 14px;
    line-height: 20px;
  }
  .realtor-phone {
    font-size: 15px;
    line-height: 20px;
  }
}

.unfounded {
  display: flex;
  background: white;
  padding: 22px 30px;
  border-radius: 5px;
  width: 100%;

  &__img {
    margin-right: 32px;

    & img {
      object-fit: contain;
      width: 100%;
    }
  }

  &__description {
    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: #EA1624;
      margin-bottom: 6px;
    }

    &__text {
      font-size: 14px;
      line-height: 18px;
      color: #333333;
    }
  }
}

.not-found-objects{
  margin-top: 10px;
  display: none;
}

@media (max-width: 576px) {
  .unfounded {
    &__img {
      display: none;
    }
  }
}


