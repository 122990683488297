.footer-top{
  &-wide-container{
    background-color: white;
    border-bottom: 1px solid #E2E2E2;
    border-top: 1px solid #E2E2E2;
    padding: 20px 15px;
  }
  &_navs{
    display: flex;
    justify-content: space-between;
    padding:0px 49px 0px 70px;
    align-items: center;
  }
}
.footer_search{
  width: 100%;
  &-wrapper{
    margin-left: 40px;
  }
}

@media (max-width: 576px) {
  .footer-top_navs{
    display: none;
  }
  .footer-top_search{
    display: none;
  }
  .footer-middle_left{
    display: none;
  }
}