.invite-block{
  padding-top: 50px;
  padding-bottom: 50px;

  &__content{
    width: 50%;
      position: relative;
    &:after{
      content: '';
      width: 444px;
      height: 327px;
      display: block;
      position: absolute;
      right: -444px;
      bottom: -70px;
      background: url(/static/images/note_cat.webp);
    }
  }
  &__text{
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: #FFFFFF;
    display: block;
    margin-bottom: 30px;
  }
}
.index-row_invite{
  background-color: #454545;
}

@media (max-width: 768px) {
  .invite-block{
    &__content{
      width: 100%;
    }
  }
  .red-button{
    &_invite{
      display: block;
    }
  }
  .invite-block{
    padding-bottom: 200px;
    &__content{
      font-size: 22px;
      &:after{
      content: '';
      width: 276px;
      height: 203px;
      display: block;
      position: absolute;
      right: calc(50% - 138px);
        bottom: -212px;
      background: url(/static/images/note_cat.png);
        background-size: contain ;
    }
    }
  }
}
@media (max-width: 768px) {
  .invite-block__text{
    font-size: 22px;
  }
}