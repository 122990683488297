.footer_nav{
  &-content{
    &_social-media{
      padding-top: 10px;
      & a{
        margin-right: 10px;
        &:hover{
          text-decoration: none;
        }
      }
    }
    &__href{
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
      color: #666666;
      display: block;
      &:not(:last-child) {
         padding-bottom: 20px;
      }
      &:hover{
        color:#EA1624;
        text-decoration: none;
      }
    }
    &__addr{
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #666666;
      display: block;
      &:not(:last-child) {
         padding-bottom: 20px;
      }
    }
    &__phone{
      font-family: Inter;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #333333 !important;
      display: block;
      padding-top: 6px;
      &:hover{
        color:#EA1624 !important;
        text-decoration: none;
      }
    }
  }
  &-title{
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    padding-bottom: 20px;
    display: block;
  }
}

.footer-middle{
  padding-top: 50px;
  padding-bottom: 50px;
  &_right{
    &-blocks{
      display: flex;
      justify-content: space-between;
      &-elem{
        max-width: 33%;
      }
    }
    &-content{
      padding-left: calcFluid(0.5, 80);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
  }
}
.warn-text{
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: #B0B0B0;
  padding-top: 32px;
}
@media (max-width: 768px) {
  .footer_nav-content__href{
    font-size: 15px !important;
  }
   .footer_nav-title{
    font-size: 15px !important;
  }
  .footer_nav-content__addr{
    font-size: 15px !important;
  }
}

@media (max-width: 576px) {
  .footer-middle{
    padding-top: 0px;
    padding-bottom: 20px;
  }
  .warn-text{
    padding-top: 0px;

    margin-top: 15px;
  }
}