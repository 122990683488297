.seo-block{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &__img{
    height: 380px;
    width: calc(50% - 30px);
    margin-right: 60px;
    &__elem{
      width: 100%;
    height: 100%;
    object-fit: contain;
    }
    & img{
      width: 100%;
    height: 100%;
    object-fit: contain;
    }
  }
  &__content{
    height: 380px;
    width: calc(50% - 30px);
  }
  & .content-text{
    padding-right: 20px;
  }
}
.seo-scroller{
  padding-top: 80px;
  padding-bottom: 100px;
  background: #F7F8FC;
  & .os-theme-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle{
    background-color: $main-color !important;
  }
}
@media (max-width: 768px) {
  .seo-scroller{
    background: white;
    padding-top: 40px;
  }
}

@media (max-width: 576px) {
  .seo-block{
    flex-direction: column;
    &__content{
      width: 100%;
    }
    &__img{
      width: 100%;
      margin-bottom: 20px;
      height: unset;
    }
  }
  .seo-scroller{
    padding-bottom: 50px;
  }
}