.breadcrumbs{
  ul{
    display: flex;
    flex-wrap: wrap;
  }
  li {
    padding-right: 5px;
    color: #A7A8A9;
    white-space: nowrap;
    & a{
      text-decoration: none;
      color: #A7A8A9;
      &:hover{
        color: $main-color;
      }
    }
  }
  margin-bottom: 20px;
  margin-top: 25px;
  & span{
    white-space: normal;
  }
}

@media (max-width: 1200px) {
  .breadcrumbs {
    font-size: 13px;
    line-height: 22px;
    color: #A7A8A9;
    margin-top: 10px;
    margin-bottom: 20px;
  }

}

@media (max-width: 768px) {
  .breadcrumbs{

  }
}