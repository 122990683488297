.footer-bottom{
  &-wide-container{
    background-color: #454545;
    padding-top: 22px;
    padding-bottom: 19px;
  }
  &_logo{
    display: flex;
    justify-content: flex-end;
  }
  &_copyright{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &_date, &_confirm{
    font-size: 14px;
    line-height: 17px;
    color: #A1A1A1 !important;
  }
  &_confirm{
    &:hover{
      text-decoration: none !important;
    }
    padding-right: 50px;
    text-decoration: underline;
  }
}