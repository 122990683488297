.pagination{
  display: flex;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #333333;


}
.pagination-container{
   width: 100%;
  margin-bottom: 90px;
}
.page-item{
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #E2E2E2;
  &_dots{
      &:hover{
        border:none !important;
      }
    }
  &.active{
    & .page-link{
      background-color: $main-color;
      color: white;
    }
  }
  &:not(:first-child) {
    margin-left: 10px;
  }
  & .page-link{
    width: 100%;
    height: 100%;
     display: flex;
  justify-content: center;
  align-items: center;
    color: #333333;
    border-radius: 5px;
    &:hover{
      border: 1px solid $main-color;
    }

  }
 & svg{
    fill: none;
    stroke: #333333;
  }
  &:hover{
    & svg{
      stroke: $main-color;
    }
  }

}
@media (max-width: 1200px) {
  .pagination-container {
    margin-left: unset !important;
    margin-top: 20px;
  }

}

@media (max-width: 576px) {
  .page-item{
    width: 23px;
    height: 23px;
  }
}