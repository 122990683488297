.index-row_services{
  background-color: #F7F8FC;
}
.services-block{
  $this: &;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  margin-bottom: 70px;
  &.active{
    #{$this} {
      &__show-more{
        &__text{
          &:after{
            background: url(/static/images/collapse_red.svg);
          }
        }
      }
    }
  }
  &__mobile{
    display: none;
    & .service-elem{
      margin-bottom: 30px;
    }
  }
  &__show-more{
    position: relative;
    display: none;
    justify-content: center;
    cursor: pointer;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top: 1px solid #E4E4E4;
    &__text{
      position: relative;
      color:#EA1624;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
       &:after {
      content: '';
      background: url(/static/images/collapse_up_red.svg);
      height: 7px;
      width: 12px;
      position: absolute;
      right: -27px;
      top: 9px;
    }

    }
  }

}
.service-elem{
  $this: &;

  width: 100%;
  display: flex;
  padding: 20px 30px;
  background-color: white;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  min-height: 200px;
  flex-direction: column;
  justify-content: space-between;
  &.active{
    background-color: #EA1624;
    color: white !important;
    z-index: 10;

    #{$this} {
      &__number{
        color: #EE434F;
      }
      &__title{
        color: white !important;
      }
      &__text{
        color: white !important;
      }
      &__more{
        color: white !important;
      }
    }
  }
  &:hover{
    text-decoration: none;
  }
  &__title{
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #333333;
    display: block;
    padding-bottom: 7px;
  }
  &__text{
    font-size: 16px;
    line-height: 24px;
    color: #555555;
    display: block;
    padding-bottom: 18px;
    position: relative;
    z-index: 8;
  }
  &__more{
    display: block;
    font-size: 13px;
    line-height: 17px;
    color: #555555;
    &:hover{
      color: #EA1624;
    }
  }
  &__number{
    position: absolute;
    font-weight: 900;
    font-size: 85px;
    line-height: 103px;
    text-align: right;
    color: #F7F8FC;
    bottom: -30px;
    right: 19px;
  }
  &__wrapper{
    position: relative;
  }
}

.left-claw{
  background: url(/static/images/left-claw.png);
  height: 70px;
  width: 35px;
  position: absolute;
  z-index: 1000;
  display: none;
}

.right-claw{
  background: url(/static/images/right-claw.png);
  height: 70px;
  width: 39px;
  position: absolute;
  z-index: 1000;
  display: none;
}

.cat-head{
  background: url(/static/images/cat_head.png);
  width: 320px;
  height: 260px;
  position: absolute;
  z-index: 9;
  display: none;
  transition: top 200ms linear;
}

@media (max-width: 992px) {
  .services-block{
    grid-template-columns: 1fr 1fr;
  }

}
@media (max-width: 768px) {
  .services-block{
    grid-template-columns: 1fr;
    margin-bottom: 0px;
    &__show-more{
      display: flex;
      width: 100%;
    }
    .service-elem__title{
    font-size: 18px;
  }
  }
  .service-elem__text{
    font-size: 13px;
  }
  .service-elem__wrapper:nth-child(n+5) {
    display: none;
  }
  .services-block__mobile{
    & .service-elem__wrapper{
      display: block;
    }
  }

  .section-wrapper.active .service-elem__wrapper:nth-child(n+5) {
    display: block;
  }

  .section-wrapper.active .services-block__show-more__text:after{
    transform: rotate(180deg);
  }
}