.custom_modal{
  display: none;
}
.modal-container{
  width: 500px;
}
.question-modal{
  $this: &;
  position: relative;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.07);
  border-radius: 5px;
  &.show{
    display: block;
    top: 15vh;
    left: calc(50vw - 250px);
    position: fixed;
    z-index: 2000;
  }
  &__wrapper{
    background-color: white;
    padding: 35px 30px;
    position: sticky;
    top: 10px;

    &.loading {
      .question-form__content{
         display: none;
      }

        & .loading-slide{
          display: flex;
        }
    }

    &.success {
      .question-form__content{
         display: none;
      }

        & .success-slide{
          display: flex;
        }
    }

   &.error {
      .question-form__content{
         display: none;
      }

        & .error-slide{
          display: flex;
        }
    }

    & span {
      display: block;
    }
  }
  &__close{
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    &:hover{
      & svg{
        fill:#EA1624;
      }
    }
  }
}
.modal-title{
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  color: #333333;
}
.fields-group{
  display: flex;
  justify-content: space-between;
  margin-bottom:  10px;
   & .form-input{
     width: calc(50% - 5px);
   }
}

.fields-group-triple{
  display: flex;
  justify-content: space-between;
  margin-bottom:  10px;
   & .form-input{
     width: calc(33% - 5px);
   }
}

.form-input{
  display: block;
  width: 100%;
  margin-bottom: 10px;
  &.error{
    border: 1px solid #EA1624;
    border-radius: 5px;
     & span {
       color: #EA1624;
     }
  }
  &__elem{
    width: 100%;
    height: 50px;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 25px 20px 14px 20px;

    &:invalid {

    }
  }
}

.form-textarea{
  $this: &;
  display: block;
  &.error{
    border: 1px solid #EA1624;
    border-radius: 5px;
    & span{
      color: #EA1624;
    }
  }
  width: 100%;
  &__elem{
    width: 100%;
    height: 100px;
    resize: none;
    border: 1px solid #E2E2E2;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 25px 20px 14px 20px;
  }
}

.loading-slide{
  display: none;
  padding: 15px;
  flex-direction: column;
  align-items: center;
  min-height: 150px;
  &__img{
    margin-bottom: 15px;
    animation-name: spin;
  animation-duration: 3000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  }
}
.success-slide{
  display: none;
  padding: 15px;
  flex-direction: column;
  min-height: 150px;
   align-items: center;
  &__img{
    margin-bottom: 15px;
  }
}
.error-slide{
  display: none;
  padding: 15px;
  flex-direction: column;
  min-height: 150px;
   align-items: center;
  &__img{
    margin-bottom: 15px;
  }
}

.slide-title{
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #333333;
}

.question-form{
  $this: &;
  margin-bottom: 30px;

  &__navs{
    display: flex;
    &_call{
      flex-direction: column;
      margin-bottom: 20px;
      #{$this} {
        &__navs{

          &__button{
            margin-right: 0px;
            margin-bottom: 10px;
            &_call{
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    &__button{
      margin-right: 25px;
    }
    &__warn{
      font-size: 13px;
      line-height: 17px;
      color: #A7A8A9;
      align-items: center;
      margin-top: 10px;
      &__href{
        color:#A7A8A9;
        text-decoration: underline;
      }
    }
    &__upload{
      display: flex !important;
      justify-content: center;
      align-items: center;
      & svg{
        fill: $main-color;
        margin-left: 10px;
      }
    }
  }
}

.question-modal{
  border-radius: 5px;
}

.question-template{
  display: none;
  &__vars{
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
  }
  &__title{
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #333333;
    margin-bottom: 15px;
  }
}

.error-field{
  color: $main-color;
  margin-bottom: 10px;
}

.modal-text{
  margin-top: 10px;
  margin-bottom: 35px;
}

.var-button{
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #7C7C7C;
  padding: 10px 17px;
  cursor: pointer;
  &:hover{
    border: 1px solid #EA1624;
    color: #EA1624;
  }
  &.active{
    border: 1px solid #EA1624;
    color: #EA1624;
  }
}

.form-input{
  $this: &;
  &__placeholder{
    position: absolute;
    left: 20px;
    top: 14px;
    font-size: 16px;
    line-height: 22px;
    color: #A7A8A9;
    transition: 0.3s;
    &.small{
      top: 5px;
      left: 10px;
      font-size: 12px;
      line-height: 15px;
    }
  }
  position: relative;
}

.form-textarea{
  margin-bottom: 10px;
  $this: &;
  &__placeholder{
    position: absolute;
    left: 20px;
    top: 14px;
    font-size: 16px;
    line-height: 22px;
    color: #A7A8A9;
     transition: 0.3s;
    &.small{
      top: 5px;
      left: 10px;
      font-size: 12px;
      line-height: 15px;
    }
  }
  position: relative;
}

@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

@media (max-width: 576px) {
  .modal-container{
  width: 320px;
}
  .question-modal.show{
    top: 0px;
    left: calc(50vw - 160px);
    max-height: 100%;
    overflow: auto;
  }
  .question-form__navs__button{
    margin-right: 0px;
  }
  .fields-group{
    flex-direction: column;
    & .form-input{
      width: 100%;
    }
  }
  .fields-group-triple{
    flex-direction: column;
    & .form-input{
      width: 100%;
    }
  }
  .question-form__navs{
    flex-direction: column;
  }
}


input[type="file"] {
  display: none;
}