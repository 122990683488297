.content-text{
	& h1{
		font-weight: 600;
		font-size: 26px;
		line-height: 32px;
		color: #333333;
	}
	font-size: 16px;
	line-height: 24px;
	color: #333333;
}
@media (max-width: 768px) {
	.content-text{
		font-size: 15px;
		& h1{
			font-size: 22px;
		}
	}
}