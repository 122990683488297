$main-color: #EA1624;

@function calcFluid($f-min, $f-max, $v-min: 320, $v-max: 1920) {
  $k: ($f-max - $f-min)/($v-max - $v-min);
  $b: $f-min - $k * $v-min;

  $b: $b * 1px;

  @return calc(#{$k} * 100vw + #{$b});
}
