@media (min-width: 1400px) {
  .container {
    max-width: 1320px;
  }
}

@media (max-width: 1200px) {
  .header-mobile {
    display: block !important;
  }
  .header-desktop {
    display: none;
  }
  .footer_search-wrapper {
    margin-left: 0px !important;
  }
  .footer-top_navs {
    padding: 30px 15px 30px 15px !important;
  }
  .category-filters{
  }
}

@media (max-width: 992px) {
  .footer-middle_right-blocks-elem {
    max-width: unset !important;
  }
  .warn-text {
    margin-top: 30px;
  }
}

@media (max-width: 576px) {
  .footer-middle_right-content {
    padding-left: unset !important;
  }
  .footer-middle_right {
    margin-top: 30px;
  }
  .footer-bottom_confirm {
    padding-right: unset !important;
  }
  .footer-bottom_logo {
    justify-content: flex-start !important;
  }
  .footer-bottom_date, .footer-bottom_confirm, .footer-bottom_logo {
    padding-top: 20px;
  }
}

@media (max-width: 368px) {
  .navs-elem {
    min-width: calc(50% - 15px);

    &-1 {
      order: 0;
    }

    &-2 {
      order: 2;
      padding-top: 20px;
    }

    &-3 {
      order: 1;
    }

    &-4 {
      order: 3;
      padding-top: 20px;
    }
  }
  .footer-top_navs {
    flex-wrap: wrap;
  }


}
