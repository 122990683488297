.magazine-block{
  margin-bottom: 90px;
  flex-direction: column;
  &__title{
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #E5E5E5;
    &__href{
      margin-top: 80px;
      white-space: nowrap;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: $main-color;
      margin-right: 40px;
      position: relative;
      &_mobile{
        width: fit-content;
        display: none;
      }
      &:hover{
        color:$main-color;
        text-decoration: none;
        & .underline{

    text-decoration: none;

        }
      }
      &:after{
        content: '';
        width: 20px;
        height: 11px;
        background: url(/static/images/href_arrow_right.svg);
        position: absolute;
        right: -32px;
      }
    }
  }
}
.underline{
  text-decoration: underline;

}

.news-widget{
  width: 100%;
  display: flex;
  & a {
    text-decoration: none;
  }
  &__right{
    max-height: 430px;
    &__wrapper{
      display: flex;
      flex-direction: column;
    }
  }

}

.big-new{
  $this: &;

  margin-right: 20px;
  display: block;
  &__img{
    border-radius: 5px;
    margin-bottom: 20px;
    position: relative;
    max-height: 430px;
    height: 430px;
    overflow: hidden;
      &_playable:after{
        content: '';
        width: 76px;
        height: 76px;
        display: block;
        position: absolute;
        background: url(/static/images/big_play.png);
        top: calc(50% - 38px);
        left: calc(50% - 38px);
      }
  }
  &__content{
    &__title{
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: #333333;
    }
  }
  &:hover{
    #{$this} {
      &__content{
        &__title{

            text-decoration: underline;
    color: $main-color;

        }
      }
    }

  }
}

.small-new{
  $this: &;


  &:not(:last-child) {
    margin-bottom: 20px;
  }
  &__content{
    margin-left: 20px;
    &__title{
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
    }
  }
  &__img{
    position: relative;
    max-height: 93px;
    max-width: 168px;
    min-width: 168px;
    width: 100%;
      &_playable:after{
        content: '';
        width: 36px;
        height: 36px;
        display: block;
        position: absolute;
        background: url(/static/images/small_play.png);
         top: calc(50% - 18px);
        left: calc(50% - 18px);
      }

  }
  &:hover{
    #{$this} {
      &__content{
        &__title{

            text-decoration: underline;
    color: $main-color;

        }
      }
    }

  }
}

@media (max-width: 1200px) {
  .news-widget{
    flex-direction: column;
  }
  .big-new{
    margin-right: 0px;
    margin-bottom: 25px;
  }
}
@media (max-width: 768px) {
  .magazine-block__title__href{
    &_desktop{
      display: none;
    }
    &_mobile{
      display: flex;
    }
  }
}
@media (max-width: 576px) {
  .big-new__img{
      height: unset;
      &__elem{
        object-fit: contain !important;
      }
    
  }
  .small-new__img{
    width: 100%;
    max-width: 40%;
    min-width: unset;
    border-radius: 5px;
    & .for_img{
      border-radius: 5px;
      min-height: 80px;
    }
}


}

@media (max-width: 768px) {
  .small-new__content__title{
    font-size:13px;
    line-height: 17px;
  }
  .big-new__content__title{
    font-size: 15px;
  }
   .magazine-block{
    margin-bottom: 40px;
     &__title{
       padding-bottom: 0px;
       border: none;
       & .h2_title{
         margin-bottom: 0px;
       }
     }
  }
  .magazine-block__title__href_mobile{
    margin-top: 30px;
  }
  .index-row_news{
    background: #F7F8FC;
  }
}
