.leaders-block{
  display: grid;
  gap: 30px;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 100px;
}
.leader-card{
  display: flex;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #ECECEC;

  &:hover{
    text-decoration: none;
  }
  & span{
    display: block;
  }
  &__title{
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    margin-bottom: 5px;
    &__small{
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      margin-bottom: 4px;
    }
  }
  &__text{
    font-size: 16px;
    line-height: 20px;
    color: #333333;
    margin-bottom: 20px;
    &__small{
      font-size: 16px;
      line-height: 20px;
      color: #333333;
      margin-bottom: 15px;
    }
  }
  &__img{
    width:50%;
    &__elem{
     width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  &__content{
    width: 50%;
    padding: 30px;
    background-color: #F7F8FC;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }
}
.sales-leaders{
  margin-bottom: 100px;
}

.phone-button{
  padding: 13px 23px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  background-color: #EA1624;
  border: 1px solid #EA1624;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & span{
    color: white;
  }
  &_leader{
    margin-bottom: 10px;
  }
  & a{
    color: white;
  }
  &.active{
     & .show{
       display: none;
     }
    & .short{
      display: none;
    }
    & .long{
      display: block;
    }
  }
  & .show{
    font-size: 13px;
    line-height: 19px;
    color: #FFFFFF;
    text-decoration: underline;
    padding-left: 10px;
  }
  & .long{
    display: none;
  }
  &:hover{
    & .show{
      color: #EA1624;
    }
    & span{
      color: $main-color
    }

    & a{
      color: $main-color;
    }
    background-color: white;
    color: #EA1624;
  }
}

.inverse-button{
  border: 1px solid #EA1624;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #EA1624;
  border-radius: 5px;
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
  cursor: pointer;
  &:hover{
    color: #333333;
    border-color: #fc3c49;
  }
}

@media (max-width: 992px) {
  .leader-card{
    flex-direction: column;
    &__img{
      width: 100%;
    }
    &__content{
      width: 100%;
       height: 100%;
    }
  }
}
@media (max-width: 768px) {
  .leader-card{
    &__content{
      padding: 30px 15px;
    }

  }
  .leader-card__text__small{
    font-size: 13px;
    line-height: 19px;
  }
  .leader-card__title__small{
    font-size: 13px;
line-height: 19px;
  }
  .leader-card__title{
    font-size: 18px;
line-height: 22px;
  }
  .leader-card__text{
     font-size: 14px;
    line-height: 19px;
  }
  .phone-button_leader{
    font-size: 15px;
  }
  .leader-card{
    & .inverse-button{
      font-size: 15px;
    }
  }


}
@media (max-width: 576px) {
  .leaders-block{
    display: flex;
    overflow: visible;
    overflow-x: scroll;
    margin-bottom: 40px;
  }
  .leader-card{
    min-width: 300px;
    &__content{
      padding: 30px 15px;
    }

  }
  .phone-button{
    padding-left: 0px;
    padding-right: 0px;
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 368px) {
  .leader-card{
    min-width: 240px;
  }
}